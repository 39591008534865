<template>
	<div v-show="isActive"><slot></slot></div>
</template>

<script>
export default {
	mounted() {
		this.isActive = this.selected;
	},

	created() {
		this.$parent.tabs.push(this);
	},

	data() {
		return {
			isActive: false,
		};
	},

	props: {
		name: { required: true },
		selected: { default: false },
	},

	computed: {
		// href() {
		//     return '#' + this.name.toLowerCase().replace(/ /g, '-');
		// }
	},
};
</script>

<style></style>
