export const crudable = {
	// props: ['resourceId', 'crudActions', 'attachedQuery', 'mode'],
	props: ["crudActions", "attachedQuery", "mode"],

	data() {
		return {
			resourceId: null,
			resource: {},
			errorResource: {},
			crudAction: null,
			crudLoading: false,
			errors: {},
			resourceStatus: { status: "initiated", count: 0 },
		};
	},

	methods: {
		/**
		 * Set the type of CRUD action on the component
		 *
		 * @param {string} action
		 * @returns {void}
		 */
		setCrudAction(action) {
			this.crudAction = action;
		},

		setResourceEndpoint(endpointKey, params) {
			this.resourceEndpoint = this.$api.resource(endpointKey, params);
		},

		updateResourceStatus(new_status) {
			this.resourceStatus = {
				status: new_status,
				count: this.resourceStatus.count + 1,
			};
		},

		fetchOne(query) {
			this.crudLoading = true;

			this.resourceEndpoint
				.fetchOne(this.resourceId, this.prepareQuery(query))
				.then((response) => {
					this.crudLoading = false;
					this.successResponse(this.resourceId, response, "loaded");
				})
				.catch((e) => {
					this.crudLoading = false;
					this.errorResponse(e, "load_error");
				});
		},

		fetchAll(query) {
			this.crudLoading = true;

			this.resourceEndpoint
				.fetchAll(this.prepareQuery(query))
				.then((response) => {
					this.crudLoading = false;
					this.successResponse(null, response, "loaded");
				})
				.catch((e) => {
					this.crudLoading = false;
					this.errorResponse(e, "load_error");
				});
		},

		save() {
			this.resource.id ? this.update() : this.create();
		},

		create() {
			this.crudLoading = true;

			this.resourceEndpoint
				.create(this.resource)
				.then((response) => {
					this.crudLoading = false;
					this.successResponse(null, response, "created");
				})
				.catch((e) => {
					this.crudLoading = false;
					this.errorResponse(e, "create_error");
				});
		},

		update() {
			this.crudLoading = true;

			this.resourceEndpoint
				.update(this.resource)
				.then((response) => {
					this.crudLoading = false;
					this.successResponse(this.resource.id, response, "updated");
				})
				.catch((e) => {
					this.crudLoading = false;
					this.errorResponse(e, "update_error");
				});
		},

		delete(query) {
			this.crudLoading = true;

			this.resourceEndpoint
				.delete(this.resourceId, this.prepareQuery(query))
				.then((response) => {
					this.crudLoading = false;
					this.successResponse(this.resourceId, response, "deleted");
				})
				.catch((e) => {
					this.crudLoading = false;
					this.errorResponse(e, "delete_error");
				});
		},

		successResponse(resourceId, response, status) {
			this.assignResource(response.data);
			this.notifyActionStatus(resourceId, status, response.data);
			this.updateResourceStatus(status);
		},

		errorResponse(e, status) {
			// console.log(e)
			if (e.response && "status" in e.response && e.response.status === 422) {
				this.errors = e.response.data.errors;
			} else {
				this.assignErrorResource(e.response.data);
				this.notifyActionStatus(null, status, e.response.data.title);
				this.updateResourceStatus(status);
			}
		},

		invokeCrudAction() {
			switch (this.crudAction) {
				case "save":
					this.save();
					break;

				case "delete":
					this.delete();
					break;
			}
		},

		notifyActionStatus(resourceId, status, payload) {
			this.$emit("crudActionStatus", resourceId, status, payload);
		},

		resetResource() {
			this.updateResourceStatus("initiated");
			this.resource = {};
		},

		assignResource(resource) {
			this.resource = JSON.parse(JSON.stringify(resource));
		},

		assignErrorResource(resource) {
			this.errorResource = JSON.parse(JSON.stringify(resource));
		},

		prepareQuery(query = {}) {
			query = Object.assign(query, this.attachedQuery);
			return query;
		},
	},
};
