<template>
	<form @submit.prevent="save" class="form-bare">
		<label class="flex flex-col font-bold w-full">
			<span>Name</span>
			<input
				class="border form-input mt-1 p-2"
				name="name"
				v-model="resource.name"
				placeholder="List Name"
				required />
		</label>
		<v-button type="submit" class="btn btn-success" :loading="crudLoading">Create list</v-button>
	</form>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";

export default {
	mixins: [crudable],

	mounted() {
		this.setResourceEndpoint("prospectProjectLists", {
			team_id: this.teamId,
			project_id: this.projectId,
		});
	},

	components: {
		"v-button": Button,
	},

	data() {
		return {};
	},

	props: ["teamId", "projectId"],

	methods: {},

	computed: {
		crudName() {
			if (this.resourceId) {
				return "Save";
			}
			return "Create";
		},
	},
};
</script>
<style></style>
