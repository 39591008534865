import axios from "axios";
import store from "@/store";

const BASE_URL = "https://foresightsi-ranking.dev.networkpraxis.com";
const API_VERSION = "v1";

class FSIPRankingClient {
	getRankBuilders() {
		let resourceURL = "/rank-builders";
		return this.axios.get(resourceURL);
	}

	getRankBuilder(rank_builder_ref) {
		let resourceURL = "/rank-builders/" + rank_builder_ref;
		return this.axios.get(resourceURL);
	}

	computeRank(rank_builder_ref, parameters, limit = 10, offset = 0) {
		let resourceURL = "/rank-builders/" + rank_builder_ref + "/domain-ranks/flat-json";
		parameters.limit = limit;
		parameters.offset = offset;
		return this.axios.post(resourceURL, parameters);
	}

	constructor() {
		this.baseUrl = `${BASE_URL}/${API_VERSION}`;
		this.init();
	}

	/**
	 * Initialize Axios defaults and API Map
	 */
	init() {
		this.setupAxios();
	}

	/**
	 * Setup axios
	 */
	setupAxios() {
		this.axios = axios.create({ baseURL: this.baseUrl });

		this.axios.interceptors.request.use(
			(config) => {
				const token = store.getters.auth.token;

				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				} else {
					throw new axios.Cancel(`Operation canceled by the user \n 
                                            Reson: no auth token present`);
				}

				return config;
			},
			(error) => {
				console.log(`No auth token present @ ApiProvider`);
				console.log(error);
				Promise.reject(error);
			}
		);
	}
}

const fsiprankingapi = new FSIPRankingClient();
export default fsiprankingapi;
