<template>
	<div>
		<div class="tabs">
			<ul>
				<li v-for="(tab, key) in tabs" :key="key" :class="{ 'is-active': tab.isActive }">
					<a class="tab cursor-pointer" @click="selectTab(tab.name)">{{ tab.name }}</a>
				</li>
			</ul>
		</div>

		<div class="tab-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {},

	components: {},

	data() {
		return {
			tabs: [],
		};
	},

	methods: {
		selectTab(name) {
			this.tabs.forEach((tab) => {
				tab.isActive = tab.name == name;
			});
		},
	},

	props: {},

	computed: {},
};
</script>

<style></style>
