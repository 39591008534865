<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Name
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Last updated
								</th>
								<th scope="col" class="relative px-6 py-3"></th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="row in prospect_lists" :key="row.list_id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ row.name }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										{{ row.formatted_last_updated }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<button
										v-if="activeTeamPermission != 'READ'"
										@click="deleteProspectListModal(row.list_id, row.name)"
										class="btn btn-danger-outline btn-small mr-2">
										Delete
									</button>
									<button
										@click="viewProspectList(row.list_id)"
										class="btn btn-default-outline btn-small">
										View
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<Modal v-show="confirmDeleteModal" :widthPercent="50" @close="confirmDeleteModal = false">
		<template v-slot:header>
			<h3>Are you sure you want to delete this list?</h3>
		</template>

		<template v-slot:body>
			<div class="my-4">
				Name:
				<span class="font-bold text-md">{{ deleteProspectListName }}</span>
			</div>
			<div class="flex">
				<v-button @click="cancelDeleteProspectList" class="btn btn-default mr-3">Cancel</v-button>
				<v-button @click="confirmDeleteProspectList" :loading="deletingList" class="btn btn-danger"
					>Delete list</v-button
				>
			</div>
		</template>
	</Modal>
</template>

<script>
import fsiprankingapi from "@/services/api-clients/fsipranking";
import moment from "moment";
import Modal from "@/components/Modal";
import Button from "@/components/ui/Button";
import { mapGetters } from "vuex";

export default {
	created() {},

	mounted() {
		this.getProspectLists();
	},

	components: {
		"v-button": Button,
		Modal,
	},

	data() {
		return {
			prospect_lists: [],
			confirmDeleteModal: false,
			deleteProspectListId: null,
			deleteProspectListName: "",
			deletingList: false,
		};
	},

	methods: {
		viewProspectList(listId) {
			this.$router.push({
				name: "prospects-project-prospect-list",
				params: {
					teamId: this.teamId,
					projectId: this.projectId,
					listId: listId,
				},
			});
		},

		deleteProspectListModal(listId, name) {
			this.confirmDeleteModal = true;
			this.deleteProspectListId = listId;
			this.deleteProspectListName = name;
		},
		cancelDeleteProspectList() {
			this.confirmDeleteModal = false;
			this.deleteProspectListId = null;
		},
		async confirmDeleteProspectList() {
			this.deletingList = true;
			await this.deleteProspectList(this.deleteProspectListId);
			this.deletingList = false;
			this.confirmDeleteModal = false;
			this.deleteProspectListId = null;
			this.deleteProspectListName = "";
		},

		async getProspectLists() {
			try {
				let response = await this.$api
					.resource("prospectProjectLists", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.fetchAll();

				for (var i in response.data.prospect_lists) {
					response.data.prospect_lists[i].formatted_last_updated = moment(
						response.data.prospect_lists[i].last_updated
					).format("ddd, MMM Do YYYY, HH:mm:ss");
				}
				this.prospect_lists = response.data.prospect_lists;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		async deleteProspectList(listId) {
			try {
				let response = await this.$api
					.resource("prospectProjectLists", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.delete(listId);
				this.getProspectLists();
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},
	},

	props: ["teamId", "projectId"],

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>
