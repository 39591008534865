<template>
	<div class="flex justify-between mb-3">
		<v-button @click="back" class="btn btn-small btn-default"
			><span v-html="back_icon"></span> Back to workspace</v-button
		>
	</div>
	<div class="heading heading-big">
		{{ resource.name }}
	</div>

	<Tabs>
		<!-- <Tab name="Overview" :selected="true">
            <p>Test</p>
        </Tab> -->
		<Tab name="Prospect lists" :selected="true">
			<div class="lg:w-10/12">
				<div class="flex justify-between flex-row-reverse mb-3">
					<!-- <div class="m-3 font-bold text-lg">Prospect lists</div> -->
					<v-button
						v-if="activeTeamPermission != 'READ'"
						@click="createProspectList"
						class="btn btn-small btn-add-new h-10"
						>New<span class="ml-3" v-html="plus_icon"></span
					></v-button>
				</div>
				<ProspectListTable :teamId="teamId" :projectId="projectId" :key="prospectListTableComponentKey" />
			</div>
		</Tab>
		<!-- <Tab name="Saved searches">
            <div class="w-10/12">
                <ProspectProjectSavedSearchTable :teamId="teamId" :projectId="projectId" />
            </div>
        </Tab> -->
		<Tab name="Settings" v-if="activeTeamPermission != 'READ'">
			<div class="mb-3">
				<v-button @click="trashProject" class="btn btn-danger"
					><span v-html="trash_icon" class="mr-1"></span> Trash project</v-button
				>
			</div>
		</Tab>
	</Tabs>

	<Modal v-show="confirmTrashModal" :widthPercent="50" @close="confirmTrashModal = false">
		<template v-slot:header>
			<h3>Are you sure you want to trash the project?</h3>
		</template>

		<template v-slot:body>
			<div class="flex">
				<v-button @click="cancelTrashProject" class="btn btn-default mr-3">Cancel</v-button>
				<v-button @click="confirmTrashProject" :loading="trashingProject" class="btn btn-danger"
					>Trash project</v-button
				>
			</div>
		</template>
	</Modal>

	<Modal v-show="newProspectsListModal" :widthPercent="60" @close="newProspectsListModal = false">
		<template v-slot:header>
			<h3>New prospect list</h3>
		</template>

		<template v-slot:body>
			<CreateProspectListForm
				:teamId="teamId"
				:projectId="projectId"
				@crudActionStatus="handleProspectListCrud" />
		</template>
	</Modal>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";
import ProspectListTable from "@/components/projects/prospects/ProspectListTable";
// import ProspectProjectSavedSearchTable from '@/components/projects/ProspectProjectSavedSearchTable';
import CreateProspectListForm from "@/components/projects/prospects/CreateProspectListForm";
import Tab from "@/components/ui/Tab";
import Tabs from "@/components/ui/Tabs";
import Modal from "@/components/Modal";
import { mapGetters } from "vuex";
// import ManageProject from '@/components/projects/ManageProject';

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>`;
const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;
const trash_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>`;

export default {
	mixins: [crudable],

	created() {
		if (this.projectId) {
			this.resourceId = this.projectId;
			this.setResourceEndpoint("teamProjects", {
				team_id: this.teamId,
				project_type: "prospects",
			});
			this.fetchOne();
		}
	},

	components: {
		"v-button": Button,
		ProspectListTable,
		// ProspectProjectSavedSearchTable,
		CreateProspectListForm,
		Tab,
		Tabs,
		Modal,
		// ManageProject,
	},

	props: ["teamId", "projectId"],

	data() {
		return {
			confirmTrashModal: false,
			trashingProject: false,
			// deleteName: null,
			// deleteError: null,
			newProspectsListModal: false,
			prospectListTableComponentKey: 0,
			back_icon,
			plus_icon,
			trash_icon,
		};
	},

	methods: {
		trashProject() {
			this.confirmTrashModal = true;
		},
		cancelTrashProject() {
			this.confirmTrashModal = false;
		},
		async confirmTrashProject() {
			this.trashingProject = true;
			try {
				let response = await this.$api
					.resource("projectTrash", {
						team_id: this.teamId,
						project_type: "prospects",
						project_id: this.projectId,
					})
					.put({ trashed: true });

				this.$router.push({
					name: "workspace-projects",
					params: { teamId: this.teamId },
				});
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		createProspectList() {
			this.newProspectsListModal = true;
		},

		handleProspectListCrud(resourceId, e, payload) {
			if (e == "created") {
				this.prospectListTableComponentKey += 1;
				this.newProspectsListModal = false;
			}
		},

		back() {
			this.$router.push({
				name: "workspace-projects",
				params: { teamId: this.teamId },
			});
		},
	},

	watch: {
		resourceStatus(status) {
			if (status.status == "deleted") {
				this.$router.push({
					name: "workspace-projects",
					params: { teamId: this.teamId, deletedProjectId: this.resourceId },
				});
			} else if (status.status == "delete_error") {
				this.deletingProject = false;
				this.deleteError = this.errorResource.title;
			} else if (status.status == "load_error") {
				this.back();
			}
		},
		$route(to, from) {
			// console.log(to, from)
			this.confirmTrashModal = false;
			this.newProspectsListModal = false;
		},
	},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
