<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper" @click="maskClick">
				<div v-if="loading">Loading ...</div>

				<div else class="modal-container">
					<div class="modal-header">
						<slot name="header"></slot>
						<button class="close modal-default-button" @click="$emit('close')">
							<span v-html="close_icon"></span>
						</button>
					</div>

					<div class="modal-body">
						<slot name="body"></slot>
					</div>

					<!-- <div class="modal-footer">
                        <slot name="footer"></slot>
                        <button class="close modal-default-button" @click="$emit('close')">Cancel</button>
                    </div> -->
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
let close_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>`;

export default {
	props: ["widthPercent"],

	emits: ["close"],

	data() {
		return {
			loading: false,
			close_icon: close_icon,
		};
	},

	mounted() {
		//this.widthPercent ? $('.modal-container').css('width', this.widthPercent + '%'): null;
	},

	created() {},

	watch: {},

	computed: {},

	methods: {
		maskClick(e) {
			if (e.target.className == "modal-wrapper") {
				this.$emit("close");
			}
		},
	},
};
</script>
